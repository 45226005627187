import axios from 'axios';
import { getResources } from '../../../../js/utils';
import { URL_PLAYER } from '../../../../js/constants';

/**
 * Support chat on
 */
function initLivetex() {
  let playerData;

  const makeLivetexConversation = async () => {
    if (userLogged) {
      if (!playerData) {
        playerData = await axios.get(URL_PLAYER);
      }
      const { id: altId, name: playerName } = playerData.data.currentPlayer;

      LiveTex.setConversationAttributes({ altId, playerName: playerName || 'Игрок' }, {});
      LiveTex.setVisitorAttributes((complete) => {}, (error) => {}, {
        name: { name: playerName || 'Игрок', is_editable: true },
        contacts: [],
      });
    } 
    
    LiveTex.showWelcomeWindow();

  };

  (window['l' + 'i' + 've' + 'Te' + 'x'] = true),
    (window['live' + 'TexI' + 'D'] = 141674),
    (window['l' + 'iveT' + 'ex' + '_o' + 'bject'] = true);

  window.LiveTex = {
    onLiveTexReady() {
      // LiveTex.hideLabel();
      jQuery('body').on('click', '.button_chat_livetex', () => {
        makeLivetexConversation();
      });
    },
  };
}

export function callLiveTex() {
  initLivetex();
  const t = document['cre' + 'ateEl' + 'emen' + 't']('script');
  t.type = 'text/javascript';
  t.async = true;
  t.src = '//c' + 's15.livet' + 'ex.ru/js' + '/clie' + 'nt.j' + 's';
  const c = document['g' + 'etElement' + 'sByTagN' + 'am' + 'e']('script')[0];
  if (c) c['par' + 'e' + 'ntN' + 'od' + 'e']['inse' + 'rtBe' + 'for' + 'e'](t, c);
  else {
    document['docu' + 'mentEle' + 'm' + 'ent']['fir' + 'stC' + 'hild'][
      'a' + 'p' + 'pe' + 'ndCh' + 'i' + 'l' + 'd'
    ](t);
  }
}

// TODO remove load function after testing chat on production (if change link in resources not needed)
// make chat list static
async function loadSocialChats() {
  try {
    const resources = await getResources('onlineChat');
    // const resources = {"viber.name":"вайбер","telegram.link":"https://telegram.me/livetex_bot","messenger.link":"https://m.me/ru.livetex/","viber.link":"viber://pa/?chatURI=LiveTex"};
    if (resources) {
      const blockedSocials = ['vk', 'odnoklassniki'];
      $.each(resources.data, (i, item) => {
        if (i.indexOf('.link') >= 0) {
          // TODO filter json
          const network = i.split('.');
          const prefix = network.length > 0 ? network[0] : false;
          let name = '';
          if (!showAllSocial && blockedSocials.includes(prefix)) {
            return;
          }
          if (prefix) {
            if (resources.data[`${prefix}.name`]) {
              name = resources.data[`${prefix}.name`];
            }
            $('.js-chat-list .main-menu__item-last').before(`<li class="main-menu__item">
                <a href="${item}" class="main-menu__link">
                  <div class="main-menu__icon-wrapp">
                    <svg class="icon-sprite">
                      <use xlink:href= "/mobile/img/icons/sprites/common-sprite.svg#${prefix}"></use> 
                    </svg>
                  </div>
                  <span class="main-menu__label">${name}</span>
                </a>
            </li>`);
          }
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
}

loadSocialChats();
