import { get, post } from 'axios';
import scrollToElement from 'scroll-to-element';
import formDataEntries from 'form-data-entries'
import { URL_RESOURCES_PREFIX, URL_RESOURCES_SUFFIX } from "./constants"

function serializeForm($) {
  $.fn.serializeFormJSON = function () {
    const o = {};
    const a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };
}

/**
 * Format date from milliseconds to DD.MM.YY
 * @param {number} ms
 * @returns {string} formatted value
 */
export function formatDateDDMMYY(ms) {
    const date = new Date(ms);
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let min = date.getMinutes();

    m = m < 10 ? `0${m}` : m;
    d = d < 10 ? `0${d}` : d;
    h = h < 10 ? `0${h}` : h;
    min = min < 10 ? `0${min}` : min;

    return `${d}.${m}.${y}`;
}

export function handleContentToggle(deltaHeight) {
    $('.toggle-block').click(function () {
        const $toggler = $(this);
        const $content = $(this).next();
        const blockClass = this.className.split('__')[0];
        const headerHeight = $('.main-header').height() + deltaHeight;

        if ($content.hasClass(`${blockClass}__content_active`)) {
            $(`.${blockClass}__content`)
                .slideUp('fast')
                .removeClass(`${blockClass}__content_active`);
            $content.slideUp('fast');
        } else {
            $(`.${blockClass}__content`)
                .slideUp('fast')
                .removeClass(`${blockClass}__content_active`);
            $content.slideDown('fast').addClass(`${blockClass}__content_active`);
        }

        const items = $(this);
        setTimeout(() => {
            $('html, body').animate(
                {
                    scrollTop:
                    $(items)
                        .first()
                        .offset().top - headerHeight,
                },
                500,
            );
        }, 200);
    });
}

function initContentToggler(deltaHeight = 16) {
  document.addEventListener('DOMContentLoaded', () => handleContentToggle(deltaHeight));
}

function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

/**
 * Check the input character is number
 * @param {number} key - keyCode
 * @returns {boolean}
 */
export const onlyNumbersTyped = key => (key >= 46 && key <= 57) || (key >= 96 && key <= 105) || key === 8;

/* Get random integer number
* @param {number} min - minimal random value
* @param {number} max - maximal random value
*/
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);


function renderValueNames(values, valueNames) {
  const valuesList = [...values];
  valuesList.forEach((item) => {
    const value = item.textContent;
    const valueRendered = declOfNum(Number(value), valueNames);
    item.innerHTML = `${value} ` + `<span>${valueRendered}</span>`;
  });
}

/**
 * Convert phone number from InputMasked plugin
 * @param {string} phone
 */
const convertPhone = (phone) => phone.replace(/\D+/g, '')

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}


/**
 * Parsing URL
 * @param url
 * @returns {{}}
 */
export function getParamsFromURL(url) {
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    const obj = {};

    if (queryString) {
        queryString = queryString.split('#')[0];
        const arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            const a = arr[i].split('=');
            const paramName = a[0];
            obj[paramName] = typeof a[1] === 'undefined' ? true : a[1];
        }
    }

    return obj;
}


function localStorageOpearate(paramName, type = 'get', paramValue) {
  switch (type) {
    case 'get':
      var gettedInfo = localStorage.getItem(paramName);
      return gettedInfo;
      break;
    case 'set':
      localStorage.setItem(paramName, paramValue);
      return false;
      break;
    case 'remove':
      localStorage.removeItem(paramName);
      return false;
      break;

    default:
      return false;
  }
}


/**
 * Get scope for resources
 * @returns {Promise<void>}
 */
export const getResources = async (scope) => {
    try {
        const url = URL_RESOURCES_PREFIX + scope + URL_RESOURCES_SUFFIX;
        const { data } = await get(url);
        return data;
    } catch (err) {
        console.log(err.message);
    }
}

export class Form {
  /**
     * Form constructor
     * @param { HTMLElement } elem - The form
     * @param { Boolean } isValid - Is the form valid
     * @param { HTMLElement } submitBtn - Button which submit the form
     * @param { Object } patterns - RegExp for validation
     */
  constructor(elem, submitBtn, isValid = false, patterns) {
    this.elem = elem;
    this.isValid = isValid;
    this.submitBtn = submitBtn || this.elem.querySelector('button');

    /**
         * RegExp for fields validation
         * @type {{digits: RegExp, letters: RegExp, email: RegExp, password: Regexp, qiwi: RegExp, visa_rus: RegExp, visa_rus_holder: RegExp, visa_uah_holder: RegExp, yandexmoney: RegExp, ecommpay: RegExp, webmoney: RegExp}}
         */
    this.patterns = {
      digits: /^\d+$/,
      letters: /[a-zA-Z]{2,}$/,
      email: /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([A-Za-z0-9_-]+\.)+[A-Za-z0-9]([A-Za-z0-9-])+$/i,
      password: /[\s\S]{6,120}/,
    };

    this.errorMessages = {
      name: 'Укажите имя',
      email: 'Неверный email',
      message: 'Поле с сообщением пустое',
    };

    this.addListeners();
  }

  addListeners() {
    const fieldsColl = this.elem.querySelectorAll('input:not([type="hidden"]):not(.unrequired)');
    const fields = [];
    const checkboxes = [];
    const rulesCheck = document.querySelector('#rules-check');

    rulesCheck.addEventListener('change', () => {
      const rulesError = document.createElement('span');
      rulesError.className = 'form-block__error form-block__error_rules';
      rulesError.textContent = 'Вы должны согласиться с правилами казино';
      if (rulesCheck.checked) {
        rulesCheck.classList.remove('checkbox-wrapp__checkbox_error');
        this.submitBtn.removeAttribute('disabled');
        $('.form-block__error_rules').remove();
      } else {
        rulesCheck.classList.add('checkbox-wrapp__checkbox_error');
        rulesCheck.parentNode.appendChild(rulesError);
        this.submitBtn.setAttribute('disabled', 'disabled');
      }
    });

    for (const field of fieldsColl) {
      if (field.type === 'checkbox') {
        checkboxes.push(field);
        this.validateCheckboxes(checkboxes);

        field.addEventListener('change', () => {
          this.validateCheckboxes(checkboxes);
        });
      } else {
        const fieldProto = Object.assign({}, field.dataset);
        fieldProto.valid = false;
        fieldProto.value = field.value;
        fields.push(fieldProto);

        field.addEventListener('input', () => {
          fieldProto.value = field.value;
          this.validateInputs(fields);
        });

        field.addEventListener('paste', () => {
          fieldProto.value = field.value;
          this.validateInputs(fields);
        });
      }
    }
  }

  /**
     * @param { Array } fields - Form inputs
     */
  validateInputs(fields) {
    fields.forEach((field) => {
      const pattern = this.patterns[field.type] || /[\s\S]/;
      field.valid = pattern.test(field.value);
    });

    this.isInputsValid = fields.every(field => field.valid);
    this.validateAll();
  }

  validateCheckboxes(inputs) {
    this.allChecked = false;
    if (inputs.length === 0) {
      return (this.allChecked = true);
    }
    this.allChecked = inputs.every(input => input.checked);
    this.validateAll();
  }

  validateAll() {
    this.isValid = this.isInputsValid && this.allChecked;
  }
}

function initSecondaryMenu() {
  document.addEventListener('DOMContentLoaded', () => {
    const path = window.location.pathname;
    document
      .querySelector(`.secondary-menu__link[href="${path}"]`)
      .classList.add('secondary-menu__link_active');
  });
}

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

/**
 * Insert HTML element after another
 * @param elem Element which insert
 * @param refElem Element after with insert
 */
export const insertAfter = (elem, refElem) =>
  refElem.parentNode.insertBefore(elem, refElem.nextSibling);

/**
 * Show error message from server on ajax call
 * @param { String } name Invalid input's name
 * @param { String } text Error message text
 */
export const showErrorMessage = (name, text) => {
  const input = document.querySelector(`input[name="${name}"]`)

  if (input) {
    if (input.classList.contains('js-invalid')) {
        return
    }
    input.classList.add('form-block__input_error', 'js-invalid')
    const messageContainer = document.createElement('span')
    messageContainer.classList.add('form-block__tooltip', 'form-block__tooltip_error', 'form-block__tooltip_visible')
    messageContainer.textContent = text
    insertAfter(messageContainer, input)
    $('html, body').animate({
      scrollTop: $(messageContainer).offset().top - $(messageContainer).outerHeight()
    }, 600);
  } else {
    const button = document.querySelector(`button[type="submit"]`);
    button.insertAdjacentHTML('beforebegin', `<div class="form-block"><span class="form-block__tooltip form-block__tooltip_error form-block__tooltip_visible">${text}</span></div>`);
  }
}

/**
  * Form constructor
  * @param { HTMLElement } form - The form
  */
export function clearFormErrors(form) {
  const errorMessages = form.querySelectorAll('span.form-block__tooltip_error');
  const errorFields = form.querySelectorAll('.form-block__input_error');
  if (errorMessages !== null) {
    errorMessages.forEach(message => message.parentNode.removeChild(message))
    errorFields.forEach(field => field.classList.remove('form-block__input_error', 'js-invalid'))
  }
}

const simpleHandleError = error => console.log(error);

export function disableFormButton(form, disabled){
  $(form).find('button').prop('disabled', disabled);
}

export function scrollToFirstError() {
  const firstError = document.querySelector('.form-block__tooltip_error');
  const offset = screen.height / 4;
  scrollToElement(firstError, {
      offset: -offset,
      ease: 'out-expo',
  });
}

// Method for FormData take the form keys and values and put them in an object
export const objectFromFormData = (form) => {
  const values = {};
  for (let [key, value] of formDataEntries(form)) {
      if (values[key]) {
          if (!(values[key] instanceof Array)) {
              values[key] = new Array(values[key]);
          }
          values[key].push(value);
      } else {
          values[key] = value;
      }
  }
  return values;
}

/**
 * Create modal with custom text
 * @param {string} title
 * @param {string} info
 * @param {object} callback
 */
export function openInfoPopup(title, info, callback) {
  const callbacks = callback || {
    open() {
      document.body.classList.add('modal-opened');
      $panel.find('.popup__link_close').click(() => {
        $.magnificPopup.close();
        document.body.classList.remove('modal-opened')
    });
    },
    close() {
      document.body.classList.remove('modal-opened')
    },
  };
  const $panel = $('#info-popup');

  $panel.find('.popup__h3').text(title);
  $panel.find('.popup__text').html(info);
  $.magnificPopup.open({
      items: {
          src: '#info-popup',
      },
      callbacks,
  });
}

export {
  serializeForm,
  initContentToggler,
  declOfNum,
  renderValueNames,
  getUrlParameter,
  convertPhone,
  localStorageOpearate,
  initSecondaryMenu,
  simpleHandleError,
};
