import 'babel-polyfill';
import 'magnific-popup';
import Fingerprint2 from 'fingerprintjs2';
import { get, post } from 'axios';
import initMainMenu from '../../blocks/modules/_common/main-menu/main-menu';
import { openDynamicPopup } from '../../blocks/modules/_common/popup/popup';
import { callLiveTex } from '../../blocks/modules/_common/online-support/online-support';
import { getNavigatorInfo } from '../navg';
import initBackButton from '../../blocks/modules/_common/back-button/back-button';


initMainMenu();
initBackButton();
window.openInfoPopup = openDynamicPopup;

function showNoticePanel(text) {
    const message = document.createElement('div');
    message.className = 'notice';
    message.innerHTML = `
        <div class="notice__text">${text}</div>
        <span class="notice__close"></span>
      `;
    document.body.appendChild(message);

    if (!document.body.querySelector('footer')) {
        message.style.bottom = 0
    }

    const closeBtn = message.querySelector('.notice__close');
    closeBtn.addEventListener('click', () => {
        localStorage.setItem('showNotice', 'false');
        message.remove();
    });
}

function getNotice() {
    get('/service/messages/warning')
        .then(res => res.data)
        .then(data => data.data)
        .then(data => {
            if (!data) {
                return;
            }

            const { message, updateDate } = data;
            const savedDate = parseInt(localStorage.getItem('messageDate'), 10);
            const showNotice = localStorage.getItem('showNotice');

            if (showNotice === "false" && updateDate === savedDate) {
                return;
            }

            showNoticePanel(message);
            localStorage.setItem('messageDate', updateDate);
            localStorage.setItem('showNotice', 'true');
        })
        .catch(err => console.warn(err));
}

(function afterPageLoad() {
  window.addEventListener('load', () => {
    if (!window.isMobileClient) {
      callLiveTex();
    }
  });
}());

/**
 * Fingerprint
 */
(function fingerprint() {
    try {
        const options = {
            excludeAdBlock: true,
            excludeScreenResolution: true,
            excludeAvailableScreenResolution: true,
            excludePlugins: true
        };

        const navg = getNavigatorInfo();

        const fp = new Fingerprint2(options);
        fp.get(function (code) {
            const img = document.createElement('img');
            img.src = `/fingerprint?code=${code}&amp;nocache=${new Date().getTime()}`;
            img.classList.add('fingerprint-img');
            document.body.appendChild(img);
        });

        const fp2 = new Fingerprint2(options);
        fp2.get(code => post('/service/qstat', { fp: code, navg, uri: window.location.href }));
    }
    catch (e) {
        console.warn('Fingerprint blocked!')
    }
}());

getNotice()
