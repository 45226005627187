// Auth
export const URL_REG_MAIL = '/service/player/register/email';
export const URL_REG_PHONE = '/service/player/register/phone';
export const URL_AUTH = '/service/player/login';

export const URL_SEND_CODE = '/login/sendcode';
export const URL_OAUTH_LOGIN = '/oauth/login';

// Resources
export const URL_RESOURCES_PREFIX = '/service/scopes/';
export const URL_RESOURCES_SUFFIX = '/resources';

// Jackpots
export const URL_MAIN_JP = '/service/jackpots/main';
export const URL_JPS_SUM = '/service/jackpots/shortInfos?ids=';
export const URL_JPS_WINNERS = '/service/jackpot/winners';

// Player
export const URL_PLAYER = '/service/player';
export const URL_LOAD_BONUSES = '/service/player/bonuses';
export const URL_LOAD_BONUSES_NOT_ACTIVE = '/service/player/bonuses/withoutActive';

export const URL_CHANGE_PASS = '/service/player/restoreCode';
export const URL_EMAIL_VERIFY = '/service/players/verifyEmail';
export const URL_CHECK_PROFILE = '/service/player/invalidFields';

export const URL_SECURITY = '/service/players/current/security-notification-prefs';

// support
export const URL_SUPPORT = '/service/support/';

// RegExp
export const REGEXP_EMAIL = /^[\w.-]+@[\w.-]{2,}\.\w{2,}$/i;

// Others
export const LIMIT = 10; // Limit count loading list
export const GAME_IMG_DEF = '/img/games/game_def.jpg'; // Default game icon
export const GAME_IMG_DEF_RULES = '/img/games/game_def_rules.jpg'; // Default winner icon
export const URL_EMAIL_PS = '/service/payment/email/code';
export const DEPOSIT = 'deposit';

// Search game
export const SEARCH_MIN_COUNT = 2; // Minimum number of characters in search game
export const URL_RESPONSE_GAME = '/service/games?type=mobile';
export const URL_SEARCH = '/service/queries/search';
export const URL_CLICK = '/service/queries/click';
