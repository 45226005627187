export default function initBackButton() {
  const button = document.querySelector('.js-back-button');
  if (!button) {
    return;
  }
  const generateLink = () => {
    const referDomain = document.referrer.split('/')[2];
    const currentDomain = window.location.host;
    referDomain && referDomain === currentDomain ?
     history.back() :
     window.location.href = '/';
  }
  button.addEventListener('click', generateLink, false);
}
