import { getResources } from '../../../../js/utils';

// TODO: Rewrite with ES6
export function closeMainMenu() {
  $('body').removeClass('pushy-active');
  $('#main-menu')
    .removeClass('pushy-open')
    .addClass('pushy-left');
  $('#main-content').removeClass('container-push');
  $('.push').removeClass('push-push');
}

export default function initMainMenu() {
  const pushy = $('#main-menu'), // menu css class
    body = $('body'),
    container = $('#main-content'), // container css class
    push = $('.push'), // css class to add pushy capability
    siteOverlay = $('.content-overlay'), // site overlay
    pushyClass = 'pushy-left pushy-open', // menu position & menu open class
    pushyActiveClass = 'pushy-active', // css class to toggle site overlay
    containerClass = 'container-push', // container open class
    pushClass = 'push-push', // css class to add pushy capability
    menuBtn = $('.toggle-mnu'), // css classes to toggle the menu
    menuSpeed = 300, // jQuery fallback menu speed
    menuWidth = `${pushy.width()}px`; // jQuery fallback menu width
  let blockSwipeMenuIndex;

  function isInViewport(viewport, elem) {
    let elemTop = elem.offsetTop;
    let elemBottom = elemTop + elem.clientHeight;
    let viewportTop = viewport.scrollTop;
    let viewportBottom = viewportTop + viewport.scrollHeight;
    return elemBottom > viewportTop && elemTop < viewportBottom;
  }

  function togglePushy() {
    body.toggleClass(pushyActiveClass); // toggle site overlay
    pushy.toggleClass(pushyClass);
    container.toggleClass(containerClass);
    push.toggleClass(pushClass); // css class to add pushy capability
    menuBtn.toggleClass('navbar-rotate');
  }

  function openPushyFallback() {
    body.addClass(pushyActiveClass);
    pushy.animate({ right: '0px' }, menuSpeed);
    container.animate({ right: menuWidth }, menuSpeed);
    push.animate({ right: menuWidth }, menuSpeed); // css class to add pushy capability
    // menuBtn.addClass('navbar-rotate');
  }

  function closePushyFallback() {
    body.removeClass(pushyActiveClass);
    pushy.animate({ right: `-${menuWidth}` }, menuSpeed);
    container.animate({ right: '0px' }, menuSpeed);
    push.animate({ right: '0px' }, menuSpeed); // css class to add pushy capability
    // menuBtn.removeClass('navbar-rotate');
  }

  async function loadSocials() {
    try {
      const resources = await getResources('social');
      if (resources){
        var blockedSocials = ['vk', 'odnoklassniki'];
        $.each(resources, function(i, item) {
          if (i.indexOf('.link') >= 0){ //TODO filter json
            var network = i.split('.');
            var prefix = network.length > 0 ? network[0] : false;
            let name = ''
            if (!showAllSocial && blockedSocials.includes(prefix)){
                return;
            }
            if (prefix){
              if (resources[`${prefix}.name`]){
                name = resources.data[`${prefix}.name`];
                $('.js-social-links').append(`<a href="${item}" target="_blank" class="main-menu__link">
                  <div class="main-menu__icon-wrapp">
                    <svg class="icon-sprite">
                      <use xlink:href= "/mobile/img/icons/sprites/common-sprite.svg#${prefix}"></use> 
                    </svg>
                  </div>
                  <span class="main-menu__label">${name}</span>
                </a>`);
              }
            }
          }
        });
      }
    } catch (err) {
        console.log(err);
    }
  }

  loadSocials();

  // checks if 3d transforms are supported removing the modernizr dependency
  const cssTransforms3d = (function csstransforms3d() {
    const el = document.createElement('p'),
      transforms = {
        webkitTransform: '-webkit-transform',
        OTransform: '-o-transform',
        msTransform: '-ms-transform',
        MozTransform: '-moz-transform',
        transform: 'transform',
      };
    let supported = false;

    // Add it to the body to get the computed style
    document.body.insertBefore(el, null);

    for (const t in transforms) {
      if (el.style[t] !== undefined) {
        el.style[t] = 'translate3d(1px,1px,1px)';
        supported = window.getComputedStyle(el).getPropertyValue(transforms[t]);
      }
    }

    document.body.removeChild(el);
    return supported !== undefined && supported.length > 0 && supported !== 'none';
  }());

  if (cssTransforms3d) {
    // toggle menu
    menuBtn.click(() => {
      togglePushy();
    });
    // close menu when clicking site overlay
    siteOverlay.click(() => {
      togglePushy();
    });
  } else {
    // jQuery fallback
    pushy.css({ left: `-${menuWidth}` }); // hide menu by default
    container.css({ 'overflow-x': 'hidden' }); // fixes IE scrollbar issue

    // keep track of menu state (open/close)
    let state = true;

    // toggle menu
    menuBtn.click(() => {
      if (state) {
        openPushyFallback();
        state = false;
      } else {
        closePushyFallback();
        state = true;
      }
    });

    $('body').on('swiperight', () => {
      togglePushy();
    });

    // close menu when clicking site overlay
    siteOverlay.click(() => {
      if (state) {
        openPushyFallback();
        state = false;
      } else {
        closePushyFallback();
        state = true;
      }
    });
  }

  $('body').on('swiperight', () => {
    // close
    if (body.hasClass(pushyActiveClass)) {
      togglePushy();
    } else {
      return false;
    }
  });

  // Mobile submenu
  $('.main-menu__link_submenu').click(function (e) {
    const viewport = document.querySelector('.main-menu');
    e.preventDefault();
    $(this)
      .find('.icon-sprite_arrow-down')
      .toggleClass('rotate');
    $(this)
      .siblings('.main-menu__submenu')
      .slideToggle(0);

    const $menuElems = $(this)
      .parent()
      .siblings();
    $menuElems.find('.icon-sprite_arrow-down').removeClass('rotate');
    $menuElems.find('.main-menu__submenu').slideUp(0);

    if (!isInViewport(viewport, this)){ //check if element not in view
      this.scrollIntoView();
    }
  });

  // block on main page slider
  if (blockSwipeMenuIndex) {
    return false;
  }
  $('body').on('swipeleft', () => {
    if (body.hasClass(pushyActiveClass)) {
      return false;
    }
    togglePushy();
  });
}
